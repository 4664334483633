import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import { LoadingAnimation } from "./LoadingAnimation";

export const Loader = (props) => {

    const { promiseInProgress } = usePromiseTracker({area: props.area});

    return (
        promiseInProgress && (
            <LoadingAnimation className={props.className} />
        )
    );
};