import React from 'react';
import Search from "../screens/search/Search";

export const routes = [
  {
      path: "/",
      menuLabel: "Search",
      exact: true,
      private: false,
      main: props => <Search {...props} />
  }
]
