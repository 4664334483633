import React, { createRef } from 'react';
import './Explain.scss';

export class Explain extends React.Component {
    
    constructor(props) {
      super(props);
      this.state = {
          show: false
      };
    }
  
    render() {
        if (this.props.explanation) {
            return (
                <div className={"explain" + (this.state.show ? " explain--active" : "")}>
                    <a className="explain__link" onClick={() => this.setState({show: !this.state.show })}>Explain</a>
                    {this.state.show &&
                        <div className="explain__body" dangerouslySetInnerHTML={{ __html: this.props.explanation }}></div>
                    }
                </div>
            );
        }
    }
  }