import { put, call } from 'redux-saga/effects';
import { loadProfile } from '../../services/searchServices';
import * as types from '../actions';

export function* tokenSaga({params, type}) {

    try { 
        yield put({
            type: types.PUBLIC_UPDATE_TOKEN_SUCCESS,
            data: params
      });
  } catch (error) {
    
    yield put({ 
      type: types.PUBLIC_UPDATE_TOKEN_ERROR, 
      message: error.message
    });
  }
}

export function* profileSaga(params) {
  try {
      const res = yield call(loadProfile, params); 
      
      yield put({
          type: types.PUBLIC_LOAD_PROFILE_SUCCESS,
          profile: {...res.data}
    });

  } catch(error) {
    
    yield put({ 
      type: types.PUBLIC_LOAD_PROFILE_ERROR, 
      message: error.message
    });

  }
}
