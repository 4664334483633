import { put, call } from 'redux-saga/effects';
import { searchQueryService } from '../../services/searchServices';
import * as types from '../actions';

export function* searchQuerySaga(params) {
  try {

      const res = yield call(searchQueryService, params); 
      
      yield put({
          type: types.PUBLIC_SEARCH_QUERY_SUCCESS,
          ...res.data
    });

  } catch(error) {

    yield put({ 
      type: types.PUBLIC_SEARCH_QUERY_ERROR, 
      message: error.message
    });

  }
}