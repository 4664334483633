import axios from "axios";
import store from "../redux/store";

const isLocal = !!(window.location.hostname === "localhost");
const API_URL = window.location.protocol + '//' + window.location.host + '/api' ;

const httpClient = axios.create({
    baseURL: API_URL,
    headers: {},
    responseType: "json",
    responseEncoding: 'utf8'
});

httpClient.interceptors.request.use(
        config => {
            if (isLocal) {
                //config.url += ".json"
            }
            const state = store.getState()
            if (state.userReducer?.accessToken) {
                config.headers.common['Authorization'] = `Bearer ${state.userReducer.accessToken}`
                // config.headers.common['Content-Type'] = 'application/json'
            }

            return config;
        }, 
    
        error => {
            if (!axios.isCancel(error)) { 
                return Promise.reject(error)
            }
        }
    );

    httpClient.interceptors.response.use(
    
        response => {
            // status code range of 2xx
            return response.data
        }, 
        
        error => {

            if (!axios.isCancel(error)) { 
                if (error?.response?.status === 401) {
                    return Promise.reject(error)
                } 
                else {
                    return Promise.reject(error)
                } 
            }
        }
    );

export default httpClient