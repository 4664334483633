import * as types from '../actions';

const initialState = {
    message: {},
    profile: {},
    account: {},
    accessToken: null,
    idToken: null,
    isAuthenticated: false
}

export default function (state = initialState, action) {
    switch (action.type) {   
        // case types.PUBLIC_LOAD_PROFILE_SUCCESS:
        //     m = createMessage(action.message, "success");
        //     return {
        //         ...state,
        //         profile: action.profile,
        //         initialized: true,
        //         message: m
        //     };

        // case types.PUBLIC_LOAD_PROFILE_ERROR:
        //     m = createMessage(action.message, "error")
        //     return {
        //         ...state,
        //         initialized: false,
        //         profile: {},
        //         message: m
        //     };

        case types.PUBLIC_UPDATE_TOKEN_SUCCESS:
            let s = {
                ...action.data,
            }
            s.isAuthenticated = !!s.accessToken

            return {
                ...s
            };

        case types.PUBLIC_UPDATE_TOKEN_ERROR:
            return {
                ...state,
                isAuthenticated: false
            };
            
        default:
            return state;
    }
};
