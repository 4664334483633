import { put, call } from 'redux-saga/effects';
import { loadConfigService } from '../../services/searchServices';
import { getAuthConfig } from '../../utils/authConfig';
import * as types from '../actions';

export function* loadConfigSaga(params) {
  try {

    const res = yield call(loadConfigService, params); 

    res.data.auth = getAuthConfig(
      res.data.azure.tenantId,
      res.data.azure.clientId,
      res.data.azure.audience
    )

    delete res.data.azure

    yield put({ 
        type: types.PUBLIC_LOAD_CONFIG_SUCCESS, 
        data: res.data,
        initialized: true,
        message: res.message
    });

  } catch(error) {

    yield put({ 
      type: types.PUBLIC_LOAD_CONFIG_ERROR, 
      message: error.message,
      initialized: false
    });

  }
}