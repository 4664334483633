import * as types from '../actions';
import produce from "immer";

const initialState = {
    message: {},
    initialized: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case types.PUBLIC_LOAD_CONFIG_SUCCESS:
            return {
                ...action.data,
                initialized: true
            };
        case types.PUBLIC_LOAD_CONFIG_ERROR:
            return {
                ...produce(state, draftState => {

                }),
                initialized: false
            };

        default:
            return state;
    }
};
