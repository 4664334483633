import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { routes } from "./routes";
import { connect } from 'react-redux';
import './App.scss';
import { loadConfigAction } from '../redux/actions/actions';
//import { MsalAuthenticationTemplate, MsalProvider, withMsal } from '@azure/msal-react';
//import { InteractionType } from '@azure/msal-browser';
//import { AuthErrorComponent } from '../components/auth/Error';
//import { AuthLoadingComponent } from '../components/auth/Loading';
//import { CustomNavigationClient } from '../components/navigation/CustomNavigationClient';

class App extends React.Component {
  componentDidMount = () => {
      console.log("CONFIG", "LOAD")
      this.props.dispatch(loadConfigAction());
  }

  //componentDidUpdate = (prevProps, prevState, snapshot) => {
  //  if (this.props.state.user?.isAuthenticated && !prevProps.state.user?.isAuthenticated ) {
  //    this.onAuthentication()
  //  }
  //}

  //onAuthentication = async () => {
  //  console.log("APP", "AUTHENTICATED")
  //}

  //handleSignIn = async () => {
  //  this.props.pca.loginPopup(this.props.state.config.auth.loginRequest)
  //}

  //handleSignOut = async () => {
  //  this.props.pca.logoutRedirect()
  //}

  getRoute = (route, index, component) => {
    const params = {
      // key:route.path,
      path:route.path,
      exact:route.exact
    }
    
    if (route.private) {
      const Component = component
      return null
      // return (
      //     <Route {...params} render={props => 
      //       <MsalAuthenticationTemplate
      //         interactionType={InteractionType.Popup} 
      //         authenticationRequest={this.props.state.config.auth.loginRequest} 
      //         errorComponent={AuthErrorComponent} 
      //         loadingComponent={AuthLoadingComponent}>
      //           <Component {...props} />
      //       </MsalAuthenticationTemplate>
      //     } />
      // )
    } else {
      params.component = (props) => component({
        ...props
      })

      return (<Route {...params} />)
    }
  }
  
  render() {
    console.log("APP", "RENDER")

    // const navigationClient = new CustomNavigationClient(this.props.history);
    // this.props.pca.setNavigationClient(navigationClient);

    var inIframe = window.inIframe()
    
        {/* <MsalProvider instance={this.props.pca}> */}
    return (
        <Router>
          <div className="app" role="main">

            {!inIframe &&
              <div className="nav_header"> 
                <div className="icon-box">
                  <a href="https://www.aspentech.com/">
                    <svg className="svg-icon-size svg-icons_complete-logo-dims desktop-logo">
                      <use xlinkHref="#icons_complete-logo" />
                    </svg>
                    <svg className="svg-icon-size svg-icons_company-logo-dims mobile-logo ">
                      <use xlinkHref="#icons_company-logo" />
                    </svg>
                  </a>
                </div>
              </div>
            }
          
          <div className="l">  
            <Switch>
              {routes.map((route, index) => this.getRoute(route, index, route.main) )}
            </Switch>
          </div>

            {!inIframe &&

              <div className="footer__basic">

                <div className="icon-box logo-footer">
                  <svg className="svg-icon-size svg-icons_company-logo-dims">
                    <use xlinkHref="#icons_company-logo" />
                  </svg>
                </div>

                <div className="privacy">
                  <a href="https://www.aspentech.com/en/resources/other/contact-us" target="_blank" rel="noopener noreferrer">Contact Us</a>
                  <a href="https://www.aspentech.com/en/privacy-and-security " target="_blank" rel="noopener noreferrer">Privacy and Security</a>
                </div> 
                
                <div className="socialMedia">
                  
                  <div className="icon-box">
                      <a href="https://twitter.com/AspenTech" target="_blank" rel="noopener noreferrer">
                        <svg className="svg-icon-size svg-icons_social-twitter-dims">
                          <use xlinkHref="#icons_social-twitter" />
                        </svg>
                      </a>
                  </div>
                  
                  <div className="icon-box">
                      <a href="https://www.facebook.com/aspentech/" target="_blank">
                        <svg className="svg-icon-size svg-icons_social-facebook-dims">
                          <use xlinkHref="#icons_social-facebook" />
                        </svg>
                      </a>
                  </div>
                  
                  <div className="icon-box">
                      <a href="https://www.linkedin.com/company/aspen-technology/">
                        <svg className="svg-icon-size svg-icons_social-linkedin-dims">
                            <use xlinkHref="#icons_social-linkedin" />
                        </svg>
                      </a>
                  </div>

                </div>
              </div>
            }
          </div>
        </Router >
    )

     {/* </MsalProvider> */ }
  }
}

// Which props do we want to inject, given the global state?
// const mapStateToProps = state => {
//   return {
//     state: {
//       config: state.configReducer,
//       user: state.userReducer
//     }
//   }
// }

// Wrap the component to inject dispatch and state into it
// export default connect(mapStateToProps)(withMsal(App));
export default connect()(App);