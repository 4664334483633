import * as types from '../actions';
import produce from "immer";

import { enableES5 } from 'immer';
enableES5(); // https://immerjs.github.io/immer/docs/installation
//If your application needs to be able to run on older JavaScript environments, such as Internet Explorer or React Native, enable this feature.

const initialState = {
    message: {},
    search: {},
    suggestions: []
}

export default function (state = initialState, action) {

    function pad2(number) {
        return (number < 10 ? '0' : '') + number;
    }
    
    function createMessage(text, status) {
        var d = new Date();
        var date = `${d.getMonth()}/${d.getDate() + 1}/${d.getFullYear()} ${d.getHours()}:${pad2(d.getMinutes())}:${pad2(d.getSeconds())}`;
        return {
            text,
            status,
            date 
        }
    }

    var m;
    switch (action.type) {
        case types.PUBLIC_SEARCH_QUERY_SUCCESS:

            m = createMessage(action.message, "success");

            delete action.facets.count;
            var r = /^v?(?<major>\d+)(?:\.(?<minor>\d+))?(?:\.(?<build>\d+))?/;
            action.facets.product_version_facet.buckets = action.facets.product_version_facet.buckets.map( facet => {
                
                var matches = facet.val.match(r);
                
                var major = 0,
                    minor = 0,
                    build = 0;

                if (matches && matches.groups) {
                    major = matches.groups.major || 0;
                    minor = matches.groups.minor || 0;
                    build = matches.groups.build || 0;
                }

                facet.version = {
                    major: major,
                    minor: minor,
                    build: build,
                    sort: [major, minor, build].map( v => v.toString().padStart(5, 0)).join(".")
                }

                //facet.val = "test";
                return facet;
            }).sort((a, b) => b.version.sort.localeCompare(a.version.sort));


            return {
                ...produce(state, draftState => {
                    draftState.search.facets = action.facets;
                    draftState.search.highlighting = action.highlighting;
                    draftState.search.response = action.response;
                }),
                message: m
            };
        case types.PUBLIC_SEARCH_QUERY_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...produce(state, draftState => {

                }),
                message: m
            };

        case types.PUBLIC_SEARCH_MLT_SUCCESS:
            m = createMessage(action.message, "success")
            return {
                ...produce(state, draftState => {
                    draftState.search.moreLikeThis = draftState.search.moreLikeThis || {};
                    draftState.search.moreLikeThis[action.match.docs[0].id] = action.response.docs;
                }),
                message: m
            };
        case types.PUBLIC_SEARCH_MLT_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...produce(state, draftState => {

                }),
                message: m
            };


        case types.PUBLIC_SEARCH_MLT_REMOVE_SUCCESS:
            m = createMessage(action.message, "success")
            return {
                ...produce(state, draftState => {
                    delete draftState.search.moreLikeThis[action.data.id];
                }),
                message: m
            };
        case types.PUBLIC_SEARCH_MLT_REMOVE_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...produce(state, draftState => {

                }),
                message: m
            };

        case types.PUBLIC_SUGGEST_QUERY_SUCCESS:
            m = createMessage(action.message, "success")
            return {
                ...state,
                suggestions: action.data,
                message: m
            };
        case types.PUBLIC_SUGGEST_QUERY_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...produce(state, draftState => {

                }),
                message: m
            };
        
        default:
            return state;
    }
};
