import React, { createRef } from 'react';
import { connect } from 'react-redux';
import {
    searchQueryAction,
    searchMLTAction,
    searchMLTRemoveAction,
    loadConfigAction,
    suggestQueryAction,
    suggestClearAction
} from "../../redux/actions/actions";
import '../Base.scss';
import './Search.scss';
import qs from "qs";
import ReactPaginate from 'react-paginate';
import Autosuggest from 'react-autosuggest';
import moment from 'moment';
import { Element, scroller } from 'react-scroll';
import { Explain } from "../../components/explain/Explain";
import { Loader } from '../../components/loadingIndicator/Loader';

var autoSubmitOn = true;
var typeAheadOn = true;
var moreLikeThisOn = true;
var eventsOn = true;
var attachmentsOn = true;

var modes = [
    {
        label: "List",
        value: "list"
    },
    {
        label: "Grid",
        value: "grid"
    }
];

var orders = [
    {
        label: "Asc",
        value: "asc"
    },
    {
        label: "Desc",
        value: "desc"
    }
];

var defaults = {
    core: "AT_shared_search",
    filters: {},
    query: "",
    facetsOn: true,
    limit: 25,
    page: 0,
    sort: "score",
    order: "desc",
    mlt: [],
    mode: "list"
};

var querystringParamList = [
    "filters",
    "query",
    "limit",
    "page",
    "sort",
    "order",
    //"mlt",
    "mode",
    "explain"
];

var searchQueryParamList = [
    "filters",
    "query",
    "limit",
    "page",
    "sort",
    "order",
    "facetsOn",
    "core",
    "explain"
];

var sortFields = [
    {
        label: "By Relevance",
        value: "score"
    },
    {
        label: "Date",
        value: "published_date"
    }
];

var cloneDeep = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

var formatDate = (dateString) => {
    var d = new Date(dateString);
    return (d.getMonth() + 1) + "/" +d.getDate() + "/" +  d.getFullYear();
}

var formatPrice = (priceString) => {
    var pArr = priceString.split(",");
    return new Intl.NumberFormat('en-US', {
        currency: pArr[1],
        style: 'currency'
    }).format(parseInt(pArr[0]));
}

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedIndex: null,
            qFocused: false,
            qsValues: {},
            facetGroupVisibility: [],
            mlt: [],
            events: [],
            attachments: [],
            tabs: {},
            showFilters: true,
            contextualFilters: {}
        };

        this.configured = false;
        this.pageRef = React.createRef();
        this.blurTimer = null;
        this.onSubmit = this.onSubmit.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.onMLTChange = this.onMLTChange.bind(this);
        this.onEventsChange = this.onEventsChange.bind(this);
        this.onAttachmentsChange = this.onAttachmentsChange.bind(this);
        this.isFilterChecked = this.isFilterChecked.bind(this);
        this.isModeChecked = this.isModeChecked.bind(this);
    }

    configure = () => {
        if (!this.configured) {
            try {
                var facets = this.props.state.config.ui.facets[defaults.core];
                var keys = Object.keys(facets);
                keys.map( key => {
                    var facet = facets[key];

                    if (facet.expanded) {
                        if (!this.isFacetGroupVisible(facet.name)) {
                            this.toggleFacetGroupVisibility(facet.name)
                        }
                    }
                });

                this.configured = true;
            } catch (error) {
                
            }
                
        }
        
    }

    componentDidMount = () => {
        let qs = this.getQuerystringObject();

        this.props.dispatch(loadConfigAction());

        this.executeQuery(qs);

    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        //window.resizeParentIFrame();

        
        this.configure();
    }
    
    replaceTokens = (fieldName, value) => {
        var newValue = value;

        if (typeof newValue === "string" && this.props.state.config && this.props.state.config.tokens) {
            var tokens = this.props.state.config.tokens[fieldName];

            if (tokens) {
                switch (fieldName) {
                    case "url":
                        if (tokens["salesforce_domain"] && newValue.indexOf("https://esupport.aspentech.com") === 0) {
                            newValue = newValue.replace("https://esupport.aspentech.com", tokens["salesforce_domain"] + "/customersupport" )
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        
        return newValue;
    }

    fixUrl = url => {
        if (url[0] === ":") {
            url = window.location.protocol + url.substring(1);
        }

        return url;
    }

    fetchSuggestions = query => {
        if (typeAheadOn) {
            if (query && query.length) {
                this.props.dispatch(suggestQueryAction({ query: query }));
            } else {
                this.onSuggestionsClearRequested();
            }
        }
    }

    getSuggestionValue = function (suggestion) {
        return suggestion.term;
    }

    onSuggestionsFetchRequested = ({ value, reason }) => {
        switch (reason) {
            case 'input-focused':
            case 'escape-pressed':
            case 'suggestions-revealed':
            case 'suggestion-selected':
                break;
            case 'input-changed':
            default:
                this.fetchSuggestions(value);
                break;
        }
    }

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.setState({
            qsValues: {
                ...this.state.qsValues,
                query: suggestionValue
            }
        },
            () => {
                this.submitForm();
                this.fetchSuggestions(suggestionValue);
            }
        );
    }

    onSuggestionsClearRequested = () => {
        this.props.dispatch(suggestClearAction());
    };

    renderSuggestion(suggestion) {
        return (
            <span>{suggestion.term}</span>
        );
    }

    getQuerystringObject = function () {
        var retVal = window.location.search;

        if (retVal.substring(0, 1) === "?") {
            retVal = retVal.substring(1);
        }

        var qsParams = qs.parse(retVal, {
            plainObjects: true,
            allowDots: true
        });

        Object.keys(qsParams).map(key => {
            if (querystringParamList.indexOf(key) === -1) {
                delete qsParams[key];
            }

            // if (moreLikeThisOn) {
            //     if (typeof qsParams.mlt === "string") {
            //         qsParams.mlt = [qsParams.mlt];
            //     }
            // }
        });

        return qsParams;
    }

    isFilterChecked = (name, value) => {
        return (typeof this.state.qsValues.filters === "object" && this.state.qsValues.filters[name] && this.state.qsValues.filters[name].indexOf(value.toString()) > -1);
    }

    isMLTChecked = (value) => {
        return (moreLikeThisOn && typeof this.props.state.search.search.moreLikeThis === "object" && Object.keys(this.props.state.search.search.moreLikeThis).indexOf(value) > -1);
    }

    isEventsChecked = (value) => {
        return (eventsOn && typeof this.state.events === "object" && this.state.events.indexOf(value) > -1);
    }

    isAttachmentsChecked = (value) => {
        return (attachmentsOn && typeof this.state.attachments === "object" && this.state.attachments.indexOf(value) > -1);
    }

    executeQuery = (params, skipSubmit, mlt) => {
        var searchParams = cloneDeep({
            ...defaults,
            ...params
        });

        var testFilters = qs.stringify(searchParams.filters, {
            encode: false,
            allowDots: true,
            indices: false
        });

        if (!testFilters) {
            searchParams.filters = "";
        };

        var qsParams = cloneDeep(searchParams);

        Object.keys(qsParams).map(key => {
            if (querystringParamList.indexOf(key) === -1) {
                delete qsParams[key];
            }
        });

        var s = qs.stringify(qsParams, {
            encode: true,
            encodeValuesOnly:true,
            allowDots: true,
            indices: false
        });

        this.props.history.push({
            search: "?" + s
        });

        this.setState({
            qsValues: qsParams,
            selectedIndex: null
        },
            () => {
                if (!skipSubmit) {
                    Object.keys(searchParams).map(key => {
                        if (searchQueryParamList.indexOf(key) === -1) {
                            delete searchParams[key];
                        }
                    });

                    this.props.dispatch(searchQueryAction(searchParams));
                }
            }
        );

        return;
    }

    onFormChange = function () {

        this.pageRef.value = 0;

        if (autoSubmitOn && this.submitButtonRef) {
            this.submitForm();
        }
    }

    onMLTChange(event) {
        if (moreLikeThisOn) {
            if (event.target.checked) {
                this.props.dispatch(searchMLTAction({ id: event.target.value }));
            } else if (!event.target.checked && typeof this.props.state.search.search.moreLikeThis === "object" && Object.keys(this.props.state.search.search.moreLikeThis).indexOf(event.target.value) > -1) {
                this.props.dispatch(searchMLTRemoveAction({ id: event.target.value }));
            }
        }
    }

    onEventsChange(event) {
        if (eventsOn) {
            var events = this.state.events || [];
            if (event.target.checked) {
                events.push(event.target.value);
            } else if (events.indexOf(event.target.value) > -1) {
                events.splice(events.indexOf(event.target.value), 1);
            } else {
                return;
            }

            this.executeQuery({
                ...this.state.qsValues,
                events: events
            }, false, true)
        }
    }

    onAttachmentsChange(event) {
        if (attachmentsOn) {
            var attachments = this.state.attachments || [];
            if (event.target.checked) {
                attachments.push(event.target.value);
            } else if (attachments.indexOf(event.target.value) > -1) {
                attachments.splice(attachments.indexOf(event.target.value), 1);
            } else {
                return;
            }

            this.executeQuery({
                ...this.state.qsValues,
                attachments: attachments
            }, false, true)
        }
    }

    submitForm = () => {
        this.submitButtonRef.click();
    }

    onSubmit(event) {
        event.preventDefault();

        var params = cloneDeep(defaults);

        for (var el of event.target) {

            if (el.name === "q") {
                params.query = el.value;
            } else if (el.name === "limit") {
                params.limit = parseInt(el.value, 10);
            } else if (el.name === "page") {
                params.page = parseInt(el.value, 10);
            } else if (el.name === "sort") {
                params.sort = el.value;
            } else if (el.name === "order") {
                if (el.checked) {
                    params.order = el.value;
                }
            } else if (el.name === "mode") {
                if (el.checked) {
                    params.mode = el.value;
                }
            } else if (el.name.indexOf("filter.") === 0) {
                var name = el.name.substring(7);
                if (!params.filters[name]) {
                    params.filters[name] = [];
                }
                switch (el.type) {
                    case "radio":
                    case "checkbox":
                        if (el.checked) {
                            params.filters[name].push(el.value);
                        }
                        break;
                    case "submit":
                        break;
                    default:
                        params.filters[name].push(el.value);
                        break;
                }
            }
        }

        this.executeQuery(params);
    }

    toggleFacetGroupVisibility = facetGroup => {
        var i = this.state.facetGroupVisibility.indexOf(facetGroup)
        if (i > -1) {
            this.state.facetGroupVisibility.splice(i, 1);
        } else {
            this.state.facetGroupVisibility.push(facetGroup);
        }

        this.setState({ facetGroupVisibility: this.state.facetGroupVisibility });
    }

    isFacetGroupVisible = facetGroup => {
        return this.state.facetGroupVisibility.indexOf(facetGroup) > -1;
    }

    getFacetValueUi = (buckets, facetFieldName, facetFieldLabel) => {
        var values = buckets.filter(facet => {
            return (facet && facet.val && (!this.state.contextualFilters[facetFieldName] || facet.val.toLowerCase().indexOf(this.state.contextualFilters[facetFieldName]) > -1))
        });

        if (values.length > 0) {
            return values.map(facet => {
                if (facet && facet.val && facet.val !== null && facet.val !== "" && facet.count !== "0" && facet.count !== 0) {
                    var label = facet.val;
                    if (facetFieldName === "start_date_facet") {
                        label = moment.utc(facet.val).format("MMMM YYYY");
                    }
                    return (
                        <div key={facet.val} className="facet__item facet__item--checkBoxes" >
                            <input id={"filter." + facetFieldName + "--" + facet.val} name={"filter." + facetFieldName} value={facet.val} type="checkbox" checked={this.isFilterChecked(facetFieldName, facet.val)} onChange={this.onFormChange} />
                            <label htmlFor={"filter." + facetFieldName + "--" + facet.val}>{label} ({facet.count})</label>
                        </div>
                    )
                }
            })
        } else {
            return <div className="facet__item--no-results">No matches found in {facetFieldLabel}</div>
        }
            
    }

    getFacets = (searchData, searchConfig) => {
        if (searchData && searchData.facets && searchConfig && searchConfig.ui && searchConfig.ui.facets && searchConfig.ui.facets[defaults.core]) {

            var facetKeys = Object.keys(searchConfig.ui.facets[defaults.core]);

            return facetKeys.map(facetFieldName => {

                let facetUI = searchConfig.ui.facets[defaults.core][facetFieldName],
                    facetData = searchData.facets[facetFieldName],
                    show = true,
                    fields = Object.keys(facetUI.filters);

                if (fields && fields.length) {
                    show = false;

                    fields.map( fieldName => {
                        if (!show) {
                            try {
                                let selectedValues = this.state.qsValues.filters[fieldName],
                                    neededValues = facetUI.filters[fieldName],
                                    intersection = neededValues.filter(x => selectedValues.includes(x));
    
                                //console.log(selectedValues, neededValues, intersection);
                                show = !!(intersection.length > 0);
                            } catch (error) {
                                
                            }
                        }
                    });
                }

                if (show && facetUI && facetData && facetData.buckets && facetData.buckets.length) {
                    return (
                        <div className="facets__container facets__slide" key={facetFieldName}>
                            <div className={"accordion facets__category" + (this.isFacetGroupVisible(facetFieldName) ? " active" : "")} category={facetFieldName} onClick={() => this.toggleFacetGroupVisibility(facetFieldName)}>
                                {facetUI.label}
                                <div className="icon-box">
                                    <svg className="svg-icon-size svg-icons_angle-down-dims">
                                        <use xlinkHref="#icons_angle-down" />
                                    </svg>
                                </div>
                            </div>

                            {this.isFacetGroupVisible(facetFieldName) && facetData.buckets.length >= 20 &&
                                <div className={"facets__contextualFilter facets__contextualFilter--" + facetFieldName}>
                                    <input placeholder={"Search " + facetUI.label} type="search" onChange={event => this.onContextualFilterChange(facetFieldName, event)} />
                                </div>
                            }

                            <div className={"facets__items facets__items--checkBoxes panel" + (" facets__items--" + facetFieldName) + (this.isFacetGroupVisible(facetFieldName) ? " active" : "")} category={facetFieldName}>
                                {this.getFacetValueUi(facetData.buckets, facetFieldName, facetUI.label)}
                            </div>
                        </div>
                    );
                }
            });
        }
    }

    onPageClick = data => {
        this.setState({
            qsValues: {
                ...this.state.qsValues,
                page: data.selected,
                selectedIndex: null
            }
        }, () => {
            this.submitForm();
            var speed = this.state.qsValues.limit * 2.5;
            scroller.scrollTo('search__form', {
                duration: speed,
                delay: 100,
                smooth: true,
                offset: -25, // Scrolls to element + 50 pixels down the page
            });
        });
    }

    onLimitChange = event => {
        this.pageRef.value = 0;
        
        this.setState({
            qsValues: {
                ...this.state.qsValues,
                limit: event.target.value,
                selectedIndex: null
            }
        },
            this.submitForm
        );
    }

    onSortChange = event => {

        this.pageRef.value = 0;

        this.setState({
            qsValues: {
                ...this.state.qsValues,
                sort: event.target.value,
                selectedIndex: null
            }
        },
            this.submitForm
        );
    }

    onContextualFilterChange = (fieldName, event) => {
        var val = event.target.value.toLowerCase();

        var newObj = {};
        newObj[fieldName] = val;

        this.setState({
            contextualFilters: {
                ...this.state.contextualFilters,
                ...newObj
            }
        });
    }

    setSelectedIndex = id => {
        if (id !== this.state.selectedIndex) {
            this.setState({ selectedIndex: id });
        }
    }

    getHighlightFieldValue = (highlighting, doc, fieldName) => {
        if (highlighting && highlighting[doc.id] && highlighting[doc.id][`${fieldName}_highlight`]) {
            return highlighting[doc.id][`${fieldName}_highlight`];
        } else {
            return doc[fieldName];
        }
    }

    createResultItemKey(id, index, level, mltIndex) {
        mltIndex = mltIndex === undefined ? "root" : mltIndex;
        return `${id}_${index}_${level}_${mltIndex}`;
    }

    getGridCard = (doc, index, searchConfig, searchData, level = 0, mltIndex) => (
        <div key={this.createResultItemKey(doc.id, index, level, mltIndex)} className={"c-card" + " " + this.createResultItemKey(doc.id, index, level, mltIndex)}>
            <div className="c-card__media">
                <img src="/176.png" alt={doc.title} className="c-card__img" />
            </div>

            <div className="c-card__body">
                <h2 className="c-card__headline" dangerouslySetInnerHTML={{ __html: this.getHighlightFieldValue(searchData.highlighting, doc, "title") }}></h2>
                <p className="c-card__published">{moment.utc((doc.published_date || doc.created_date), moment.ISO_8601).format("MM-DD-YYYY")}</p>
                <div className="c-card__excerpt line-clamp" lines="3" dangerouslySetInnerHTML={{ __html: this.getHighlightFieldValue(searchData.highlighting, doc, "description") }}></div>
                {/* <p className="c-card__by-line"></p> */}
            </div>

            <div className="c-card__flag" >{doc.data_source}</div>

            <div className="c-card__actions ">
                {doc.url &&
                    <a className="c-card__action--read c-btn c-btn--primary" href={this.fixUrl(this.replaceTokens("url", doc.url))} target="searchResult">Read</a>
                }
                {!doc.url &&
                    <a className="c-card__action--read c-btn c-btn--primary" target="searchResult">Read</a>
                }
            </div>

            {((doc.attachments && doc.attachments.numFound) || (doc.videos && doc.videos.numFound) || (doc.events && doc.events.numFound)) &&
                <div className="item__details">
                    <ul className="item__child-list">
                        {doc.attachments && doc.attachments.numFound &&
                           <li>{doc.attachments.numFound} Attachments</li>
                        }
                            
                        {doc.videos && doc.videos.numFound &&
                            <li>{doc.videos.numFound} Videos</li>
                        }

                        {doc.events && doc.events.numFound &&
                            <li>{doc.events.numFound} Classes</li>
                        }
                    </ul>
                    <a className="item__details_link link" onClick={() => this.setSelectedIndex(index)}>View Details</a>
                </div>
            }

            {/* {moreLikeThisOn && level < 1 &&
                <label>
                    <input type="checkbox" name={"mlt"} value={doc.id} defaultChecked={this.isMLTChecked(doc.id)} onChange={this.onMLTChange} />
                    {this.isMLTChecked(doc.id) ? <span>Remove More Like This</span> : <span>More Like This</span>}
                </label>
            }

            {moreLikeThisOn && level < 1 && (typeof searchData.moreLikeThis === "object") && (Object.keys(searchData.moreLikeThis).indexOf(doc.id) > -1) &&
                <ul className="c-card-list g flex-grid-4 c-card-list--mlt">
                    {searchData.moreLikeThis[doc.id].docs.map((mltDoc, mltIndex) => (
                        this.getGridCard(mltDoc, index, searchConfig, searchData, level + 1, mltIndex)
                    ))}
                </ul>
            } */}
        </div>
    )

    getThumbnailData = doc => {
        if (doc.thumbnail_image && doc.thumbnail_image[0] === "{") {

            var data = JSON.parse(doc.thumbnail_image);

            //TODO: REMOVE THIS HACK
            //data.URL = this.fixUrl(data.URL.replace("webdev.aspentech.com", "www.aspentech.com"));

            return data;
        }
    }

    getListCard = (doc, index, searchConfig, searchData, level = 0, mltIndex) => {
        var thumbnail = this.getThumbnailData(doc);
        var showThumbnail = (thumbnail && thumbnail.URL);//(thumbnail && ["Blog","Video","Whitepaper"].indexOf(doc.data_source) > -1);

        return (
            <div key={this.createResultItemKey(doc.id, index, level, mltIndex)} className={`c-item ${this.createResultItemKey(doc.id, index, level, mltIndex)} ${showThumbnail ? "c-item--thumbnail" : ""}`}>
                
                <div className="c-item__flag">{doc.data_source}</div>

                {doc.explain &&
                    <Explain explanation={doc.explain} />
                }
                
                {showThumbnail &&
                    <div className="c-card__media">
                        <img src={thumbnail.URL} alt={thumbnail.Alt} title={thumbnail.Title} className="c-card__img" />
                    </div>
                }
                
                <div className="c-item__body">
                    {doc.url &&
                        <a className="c-item__headline" href={this.fixUrl(this.replaceTokens("url", doc.url))} target="searchResult" dangerouslySetInnerHTML={{ __html: this.getHighlightFieldValue(searchData.highlighting, doc, "title") }}></a>
                    }

                    {!doc.url &&
                        <a className="c-item__headline" target="searchResult" dangerouslySetInnerHTML={{ __html: this.getHighlightFieldValue(searchData.highlighting, doc, "title") }}></a>
                    }

                    <p className="c-item__sub-headline pipe-seperated-children">
                        {doc.data_source === "Training" && 
                            <React.Fragment>
                                {doc.product && 
                                    <span>Product: {doc.product.join(", ")}</span>
                                }

                                {doc.ceus && 
                                    <span>CEU: {doc.ceus}</span>
                                }

                                {doc.level && 
                                    <span>Level: {doc.level}</span>
                                }

                                {doc.source_id && 
                                    <span>Course Id: {doc.source_id}</span>
                                }
                            </React.Fragment>
                        }


                        {doc.data_source !== "Training" && 
                            <React.Fragment>
                                <span className="c-item__published">{moment.utc((doc.published_date || doc.created_date), moment.ISO_8601).format("MM-DD-YYYY")}</span>
                                {doc.data_source === "Product Help" && doc.product && 
                                    <span>Product: {doc.product ? doc.product.join(", ") : ""}</span>
                                }
                                
                                {doc.data_source === "Knowledge Article" && doc.source_id &&
                                    <span>Article Id: {doc.source_id}</span>
                                }

                                {doc.data_source === "Webinar" && doc.language &&
                                    <span>Language: {doc.language}</span>
                                }

                                {doc.data_source === "Patch" && doc.product && doc.product.length > 0 && 
                                    <span>Product: {doc.product[0]}</span>
                                }

                                {doc.data_source === "Patch" && doc.product_version && doc.product_version.length > 0 && 
                                    <span>Version: {doc.product_version[0]}</span> 
                                }

                                {doc.data_source === "Patch" && doc.related && doc.related.length > 0 &&
                                    <span>Applies To: {doc.related[0]}</span>
                                }

                                {doc.data_source === "Patch" && doc.parent_id && 
                                    <span>Patch Id: {doc.parent_id}</span> 
                                }

                                {(doc.data_source === "Patch" || doc.data_source === "Knowledge Article") &&
                                    <span className="support-icon">
                                        <img src="/key-icon.png" title="Support Account Required" alt="Support Account Required" />
    
                                        {/* <i className="material-icons ">vpn_key</i> */}
                                        
                                    </span>
                                }

                                {["Blog","Video","Whitepaper"].indexOf(doc.data_source) > -1 && doc.solution &&
                                    <span>Solutions: {doc.solution.join(", ")}</span>
                                }

                            </React.Fragment>
                        }
                    </p>

                    <div className="c-item__excerpt line-clamp" lines="3" dangerouslySetInnerHTML={{ __html: this.getHighlightFieldValue(searchData.highlighting, doc, "description") }}></div>
                </div>

                {/* {<p className="c-item__by-line"></p>} */}

                <div className="c-item__options tab-container">

                    <div className="tab-bar">
                        {attachmentsOn && level < 1 && doc.attachments && doc.attachments.numFound > 0 &&
                            <div className="tab">
                                <label className={"accordion accordion--attachments" + (this.isAttachmentsChecked(doc.id) ? " active" : "")} >

                                    <input type="checkbox" name={"option_" + doc.id} value={doc.id} defaultChecked={this.isAttachmentsChecked(doc.id)} onChange={this.onAttachmentsChange} />

                                    <span>{doc.attachments.numFound} Attachments</span>

                                    <div className="icon-box">
                                        <svg className="svg-icon-size svg-icons_angle-down-dims">
                                            <use xlinkHref="#icons_angle-down"></use>
                                        </svg>
                                    </div>
                                </label>
                            </div>
                        }

                        {eventsOn && level < 1 && doc.events && doc.events.numFound > 0 &&
                            <div className="tab">
                                <label className={"accordion accordion--events" + (this.isEventsChecked(doc.id) ? " active" : "")} >

                                    <input type="checkbox" name={"option_" + doc.id} value={doc.id} defaultChecked={this.isEventsChecked(doc.id)} onChange={this.onEventsChange} />

                                    <span>{doc.events.numFound} Classes</span>

                                    <div className="icon-box">
                                        <svg className="svg-icon-size svg-icons_angle-down-dims">
                                            <use xlinkHref="#icons_angle-down"></use>
                                        </svg>
                                    </div>
                                </label>
                            </div>
                        }

                        <div className="grow" />

                        {moreLikeThisOn && level < 1 &&
                            <div className="tab end">
                                <label className={"accordion accordion--mlt" + (this.isMLTChecked(doc.id) ? " active" : "")} >

                                    <input type="checkbox" name={"option_" + doc.id} value={doc.id} defaultChecked={this.isMLTChecked(doc.id)} onChange={this.onMLTChange} />

                                    <span>More Like This</span>

                                    <div className="icon-box">
                                        <svg className="svg-icon-size svg-icons_angle-down-dims">
                                            <use xlinkHref="#icons_angle-down"></use>
                                        </svg>
                                    </div>
                                </label>
                            </div>
                        }

                    </div>

                    {this.isMLTChecked(doc.id) && 
                        <div className={"tab-panel tab-panel--mlt"}>
                            {(mltIndex !== null || mltIndex !== undefined) && (typeof searchData.moreLikeThis === "object") && (Object.keys(searchData.moreLikeThis).indexOf(doc.id) > -1) &&
                                <ul className="c-list c-list--mlt">
                                    {searchData.moreLikeThis[doc.id].map((mltDoc, mltIndex) => (
                                        this.getListCard(mltDoc, index, searchConfig, searchData, level + 1, mltIndex)
                                    ))}
                                </ul>
                            }
                        </div>
                    }
                    
                    {doc.attachments && doc.attachments.numFound > 0 && this.isAttachmentsChecked(doc.id) && 
                        <div className={"tab-panel tab-panel--attachments"}>
                            <ul className="item__child-list item__child-list--attachments">
                                {doc.attachments.numFound &&  doc.attachments.docs.map(attachment => (
                                    <li key={attachment.id}>
                                        <a className="link">{attachment.title}</a>  <span>{attachment.description}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    }

                    {doc.events && doc.events.numFound > 0 && this.isEventsChecked(doc.id) && 
                        <div className={"tab-panel tab-panel--events active"}>
                            <ul className="item__child-list item__child-list--events g flex-grid-4">
                                {doc.events.numFound && doc.events.docs.map(event => (
                                    <li key={event.id} className="fi"  onClick={()=> window.open("https://esupport.aspentech.com/t_registerclass?cid=" + event.source_id, "searchResult")}>
                                        
                                        {event.child_type === "class" && 
                                            <React.Fragment>
                                                <p>
                                                    <label>Start Date / End Date</label>
                                                    {formatDate(event.start_date)} - {formatDate(event.end_date)}
                                                </p>

                                                {/* <span>{event.description}</span> */}

                                                <ul className="g flex-grid-2">
                                                    <li className="fi">
                                                        <p>
                                                            <label>Class Type</label>
                                                            {event.class_type}
                                                        </p>
                                                    </li>
                                                    <li className="fi">
                                                        <p>
                                                            <label>Location</label>
                                                            {event.location_city}, {event.location_province}<br />
                                                            {event.location_country}
                                                        </p>
                                                    </li>
                                                    <li className="fi">
                                                        <p>
                                                            <label>Language</label>
                                                            {event.language}
                                                        </p>
                                                    </li>
                                                    <li className="fi">
                                                        <p>
                                                            <label>Duration</label>
                                                            {doc.duration_days}
                                                        </p>
                                                    </li>
                                                    <li className="fi">
                                                        <p>
                                                            <label>Price</label>
                                                            {formatPrice(event.price)}
                                                        </p>
                                                    </li>
                                                </ul>

                                                <div className="grow"></div>

                                                <a className="c-btn c-btn--primary">Register Now</a>

                                            </React.Fragment>
                                        }
                                    </li>
                                ))}
                            </ul>
                            <div className="events__actions">
                                <a className="action action__see-all" href={this.fixUrl(this.replaceTokens("url", doc.url)) + "#class"} target="searchResult">View All Classes</a>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    onModeChange = (event) => {
        this.executeQuery({
            ...this.state.qsValues,
            mode: event.target.value
        }, true);
    }

    onShowFiltersChanged = (event) => {
        event.preventDefault();

        this.setState({
            showFilters: !(this.state.showFilters)
        });
    }

    isModeChecked = (mode) => {
        return this.state.qsValues.mode === mode;
    }

    isOrderChecked = (order) => {
        return this.state.qsValues.order === order;
    }

    getActiveFacetValues = () => {
        var values = [];
        let facetKeys = this.state.qsValues.filters ? Object.keys(this.state.qsValues.filters) : [];
        facetKeys.map( facetKey => {
            let facetGroup = this.state.qsValues.filters[facetKey];
            if (typeof facetGroup === "string") {
                facetGroup = [facetGroup];
            }
            facetGroup.map( facetValue => {
                var val = facetValue;

                if (facetKey === "start_date_facet") {
                    val = moment.utc(facetValue).format("MMMM YYYY");
                }
                values.push({
                    name: facetKey,
                    value: val
                })
            })
        })

        return values;
    }

    removeFacetValue = facetObj => {
        var values = {};
        if (typeof facetObj === "string" && facetObj === "*") {
            //keep as empty object;
        } else {
            values = cloneDeep(this.state.qsValues.filters);
            var i = values[facetObj.name].indexOf(facetObj.value);
            if (i > -1) {
                values[facetObj.name].splice(i, 1);
            }
        }

        this.executeQuery({
            ...this.state.qsValues,
            filters: values
        });
        
    }

    render() {
        let searchConfig = this.props.state.config,
            searchData = this.props.state.search.search,
            gridMode = (this.state.qsValues.mode === "grid"),
            facetValues = this.getActiveFacetValues(),
            facetUI = searchConfig && searchConfig.ui && searchConfig.ui.facets ? searchConfig.ui.facets[defaults.core] : {};
      
        return (
        
            <div className={"c-search screen screen--search" + (this.state.showFilters ? " c-search__facets--active" : "")}>

                <Element name="search__form"></Element>

               {/* 
                <div className="topAd">
                    <img src="/optimize.png" alt="" className="c-card__img" />
                </div>
                */}
                
                <form id="c-search__form" className="l--two-col form" method="get" action="" onSubmit={this.onSubmit}>                   
                    <input type="hidden" name="page" defaultValue={this.state.qsValues.page} ref={input => this.pageRef = input}/>
                    <input type="hidden" name="order" defaultValue={this.state.qsValues.order} />                    
                    <div className="l-main">

                        {/* added className on select sort */}
                        <select name="sort" className="by-relevance" defaultValue={this.state.qsValues.sort} onChange={this.onSortChange}>
                            {sortFields.map(sortField => (
                                <option key={sortField.value} value={sortField.value}>{sortField.label}</option>
                            ))}
                        </select>

                        <div className="c-search__orders"> 
                            {orders.map(order => (
                                <label key={order.value} className={"c-search__order c-search__order--" + order.value + (this.isOrderChecked(order.value) ? " c-search__order--active" : "")}>
                                    <input type="radio" name="order" value={order.value} checked={this.isOrderChecked(order.value)} onChange={this.onFormChange} /> <span className="c-search__order-text">{order.label}</span>
                                </label>
                            ))}
                        </div>


                        <div className="c-search__box">
                            <div className="c-search__input-item">
                                {typeAheadOn &&
                                    <Autosuggest
                                        alwaysRenderSuggestions={false}
                                        focusInputOnSuggestionClick={false}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        suggestions={this.props.state.search.suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionSelected={this.onSuggestionSelected}
                                        getSuggestionValue={this.getSuggestionValue}
                                        renderSuggestion={this.renderSuggestion}
                                        inputProps={{
                                            type: 'search',
                                            onChange: (event, newValue) => {
                                                this.onFormChange(event);
                                            },
                                            value: this.state.qsValues.query || "",
                                            name: "q",
                                            class: "c-search__input-text",
                                            placeholder: "Search",
                                            autoComplete: "off"
                                        }}
                                        theme={{
                                            container: 'c-search__input-container',
                                            containerOpen: 'c-search__input-item--open',
                                            input: 'c-search__input-text',
                                            inputOpen: 'c-search__input-text--open',
                                            inputFocused: 'c-search__input-text--focused',
                                            suggestionsContainer: 'c-search__input-item__suggestions-container',
                                            suggestionsContainerOpen: 'c-search__input-item__suggestions-container--open',
                                            suggestionsList: 'c-search__input-item__suggestions-list',
                                            suggestion: 'c-search__input-item__suggestion',
                                            suggestionFirst: 'c-search__input-item__suggestion--first',
                                            suggestionHighlighted: 'c-search__input-item__suggestion--highlighted',
                                            sectionContainer: 'c-search__input-item__section-container',
                                            sectionContainerFirst: 'c-search__input-item__section-container--first',
                                            sectionTitle: 'c-search__input-item__section-title'
                                        }}
                                    />
                                }

                                {!typeAheadOn &&
                                    <input
                                        type="search"
                                        name="q"
                                        className="c-search__input-submit c-btn c-btn--primary"
                                        placeholder="Search"
                                        autocomplete="off"
                                        onChange={event => this.onFormChange(event)}
                                        defaultValue={this.state.qsValues.query}
                                    />
                                }
                                <input type="submit" value="Search" className="c-search__input-submit c-btn c-btn--primary" style={autoSubmitOn ? { display: "none" } : {}} ref={button => this.submitButtonRef = button} />

                                <div className="c-search__modes">
                                    {modes.map(mode => (
                                        <label key={mode.value} className={"c-search__mode c-search__mode--" + mode.value + (this.isModeChecked(mode.value) ? " c-search__mode--active" : "")}>
                                            <input type="radio" name="mode" value={mode.value} checked={this.isModeChecked(mode.value)} onChange={this.onModeChange} /> {/*<span className="c-search__mode-text">{mode.label}</span>*/}
                                                <div className={"icon-box c-search__mode--" + (mode.value)}>
                                                
                                                    <svg className={"svg-icon-size svg-icons_"+ mode.value +"-hobo-dims"} >
                                                        <use xlinkHref={"#icons_"+ mode.value +"-hobo"} />
                                                    </svg>
                                                </div>    
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {searchData.response &&
                            <div className="c-search__count">Found {searchData.response.numFound} Results 
                                <a className="c-btn c-btn--primary c-search__actions--share" href={"mailto:?subject=" + encodeURIComponent("Check out my AspenTech search results") + "&body=" + encodeURIComponent(window.location.href + "&shared=true")}>Share</a>
                            </div>
                        }

                        <div className="c-search__orders"> 
                            {orders.map(order => (
                                <label key={order.value} className={"c-search__order c-search__order--" + order.value + (this.isOrderChecked(order.value) ? " c-search__order--active" : "")}>
                                    <input type="radio" name="order" value={order.value} checked={this.isOrderChecked(order.value)} onChange={this.onFormChange} /> <span className="c-search__order-text">{order.label}</span>
                                </label>
                            ))}
                        </div>
                        
                        {facetValues.length > 0 && 
                            <div className="c-search__filters c-search__filters--inline ">
                                <div className="c-search__active-facets c-tab__list">
                                    {facetValues.map( facetObj => (
                                        <a key={ facetObj.name + "_" + facetObj.value} className="c-tab c-tab__filter" onClick={() => this.removeFacetValue(facetObj)}>{facetUI[facetObj.name] ? facetUI[facetObj.name].label + ": " : ""}{facetObj.value}</a>
                                    ))}
                                    <div className="c-search__actions">
                            {facetValues.length > 0 &&
                                <a className="c-btn c-btn--primary c-search__actions--clear" onClick={() => this.removeFacetValue("*")}>Clear All</a>
                            }
                        </div>
                                </div>
                                  
                        </div>
                        }

                        

                          
                        <div className="c-search__results">
                            {searchData.response &&
                                <ul className={(gridMode ? " c-card-list g flex-grid-4" : "c-list")}>
                                    {searchData.response.docs.map((doc, index) => (
                                        <li key={doc.id} className={(gridMode ? "c-card-list__item fi" : "c-list__item")}>
                                            {gridMode ? this.getGridCard(doc, index, searchConfig, searchData) : this.getListCard(doc, index, searchConfig, searchData)}
                                        </li>
                                    ))}
                                </ul>
                               
                            }
                            
                            <Loader className="search" />
                        </div>


                        {searchData.response && searchData.response.numFound > this.state.qsValues.limit &&
                            <ReactPaginate
                                forcePage={parseInt(this.state.qsValues.page, 10)}
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                pageCount={Math.ceil(searchData.response.numFound / this.state.qsValues.limit)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={8}
                                onPageChange={this.onPageClick}

                                containerClassName={'c-search__pagination'}

                                breakClassName={'break'}
                                breakLinkClassName={'break__link'}

                                pageClassName={'page'}
                                pageLinkClassName={'page__link'}

                                activeClassName={'page--active '}
                                activeLinkClassName={'page__link--active'}

                                previousClassName={'previous'}
                                previousLinkClassName={'previous__link'}

                                nextClassName={'next'}
                                nextLinkClassName={'next__link'}

                                disabledClassName={'disabled'}
                            />
                        }
                        <select name="limit" className="c-search__limit" defaultValue={this.state.qsValues.limit} onChange={this.onLimitChange} >
                            <option value="25" key={25}>Show 25 items</option>
                            <option value="50" key={50}>Show 50 items</option>
                            <option value="100" key={100}>Show 100 items</option>
                        </select>
                    </div>

                    <div className="l-sidebar">
                        <div className={"c-search__facets" + (this.state.showFilters ? " c-search__facets--active" : "")}>
                            {this.state.showFilters}
                            <button className="c-search__facets-toggle" onClick={this.onShowFiltersChanged}> 
                                <svg className="svg-icon-size svg-icons_filter-hobo-dims">
                                    <use xlinkHref="#icons_filter-hobo" />
                                </svg>
                            </button>
                            <div className="c-search__facets-form">
                                {this.getFacets(searchData, searchConfig)}
                            </div>
                        </div>  
                    </div>
                </form>
            </div>
        );
    }
}

// Which props do we want to inject, given the global state?
const mapStateToProps = state => {
    return {
      state: {
        config: state.configReducer,
        search: state.searchReducer
      }
    }
  }

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(Search);