import { fork } from 'redux-saga/effects';
import {
    watchSearchQuery,
    watchSearchMLT,
    watchSearchMLTRemove,
    watchLoadConfig,
    watchSuggestQuery,
    watchSuggestClear,
    watchAuth,
} from './watchers';

export default function* startForman() {
    yield fork(watchSearchQuery);
    yield fork(watchSearchMLT);
    yield fork(watchSearchMLTRemove);
    yield fork(watchLoadConfig);
    yield fork(watchSuggestQuery);
    yield fork(watchSuggestClear);
    yield fork(watchAuth);
}