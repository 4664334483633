import httpClient from "../utils/httpClient";
import qs from "qs";
import { trackPromise } from 'react-promise-tracker';

export const searchQueryService = action => {
    return trackPromise(
        httpClient.get("search", { 
            params: {
                ...action.params
            },
            paramsSerializer: function( params ) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            }
        },
        "search"
    ))}
;

export const searchMLTService = action => trackPromise(
    httpClient.get("more-like-this", { 
        params: {
            ...action.params
        },
        paramsSerializer: function( params ) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    },
    "search"
));

export const loadConfigService = action => trackPromise(
    httpClient.get("config"),
    "app"
);


export const loadProfile = action => httpClient.get("profile", {
    params: {
        ...action.params
    }
})

export const suggestQueryService = action => trackPromise(
    httpClient.get("suggest", {
        params: {
            ...action.params
        }
    },
    "suggest"
));