import React from 'react';
import { Provider } from 'react-redux';
import store from "../redux/store";
//import AppContainer from './AppContainer';
import App from './App';

export const Root = (props) => {
  console.log("ROOT", "RENDER")
  return (
    <Provider store={store}>
          {/*<AppContainer {...props} /> */}
          <App {...props} />
    </Provider>
  )
}

export default Root;