import { takeLatest, debounce } from 'redux-saga/effects';
import { searchQuerySaga } from './searchQuerySaga';
import { searchMLTSaga } from './searchMLTSaga';
import { searchMLTRemoveSaga } from './searchMLTRemoveSaga';
import { suggestQuerySaga } from './suggestQuerySaga';
import { loadConfigSaga } from './loadConfigSaga';
import { suggestClearSaga } from './suggestClearSaga';
import { tokenSaga, profileSaga} from './userSaga';
import * as types from '../actions';

export function* watchSearchQuery() {
    yield debounce(250, types.PUBLIC_SEARCH_QUERY, searchQuerySaga);
}

export function* watchSearchMLT() {
    yield takeLatest(types.PUBLIC_SEARCH_MLT, searchMLTSaga);
}

export function* watchSearchMLTRemove() {
    yield takeLatest(types.PUBLIC_SEARCH_MLT_REMOVE, searchMLTRemoveSaga);
}

export function* watchLoadConfig() {
    yield takeLatest(types.PUBLIC_LOAD_CONFIG, loadConfigSaga);
}

export function* watchSuggestQuery() {
    yield takeLatest(types.PUBLIC_SUGGEST_QUERY, suggestQuerySaga);
}

export function* watchSuggestClear() {
    yield takeLatest(types.PUBLIC_SUGGEST_CLEAR, suggestClearSaga);
}

export function* watchProfile() {
    yield takeLatest(types.PUBLIC_LOAD_PROFILE, profileSaga);
}

export function* watchAuth() {
    yield takeLatest(types.PUBLIC_UPDATE_TOKEN, tokenSaga);
}