import { combineReducers } from 'redux';
import searchReducer from './searchReducer';
import userReducer from './userReducer';
import configReducer from './configReducer';

const rootReducer = combineReducers({
    searchReducer,
    userReducer,
    configReducer
});

export default rootReducer;