import { put, call } from 'redux-saga/effects';
import { suggestQueryService } from '../../services/searchServices';
import * as types from '../actions';

export function* suggestQuerySaga(params) {
  try {

      const res = yield call(suggestQueryService, params); 
      
      yield put({
          type: types.PUBLIC_SUGGEST_QUERY_SUCCESS,
          data: res.data.suggest.suggest[params.params.query] ? res.data.suggest.suggest[params.params.query].suggestions : { suggestions: [] }
    });

  } catch(error) {

    yield put({ 
      type: types.PUBLIC_SUGGEST_QUERY_ERROR, 
      message: error.message
    });

  }
}