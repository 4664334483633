import * as types from '.';

export const searchQueryAction = (params) => {
  return {
    type: types.PUBLIC_SEARCH_QUERY,
    params
  }
};

export const searchMLTAction = (params) => {
  return {
    type: types.PUBLIC_SEARCH_MLT,
    params
  }
};

export const searchMLTRemoveAction = (params) => {
  return {
    type: types.PUBLIC_SEARCH_MLT_REMOVE,
    params
  }
};

export const loadConfigAction = (params) => {
  return {
    type: types.PUBLIC_LOAD_CONFIG,
    params
  }
};

export const suggestQueryAction = (params) => {
    return {
        type: types.PUBLIC_SUGGEST_QUERY,
        params
    }
};

export const suggestClearAction = () => {
    return {
        type: types.PUBLIC_SUGGEST_CLEAR
    }
};

export const loadProfileAction = (params) => {
  return {
      type: types.PUBLIC_LOAD_PROFILE,
      params
  }
};

export const updateTokenAction = (params) => {
  return {
      type: types.PUBLIC_UPDATE_TOKEN,
      params
  }
};