export const PUBLIC_SEARCH_QUERY = 'PUBLIC_SEARCH_QUERY';
export const PUBLIC_SEARCH_QUERY_SUCCESS = 'PUBLIC_SEARCH_QUERY_SUCCESS';
export const PUBLIC_SEARCH_QUERY_ERROR = 'PUBLIC_SEARCH_QUERY_ERROR';

export const PUBLIC_SEARCH_MLT = 'PUBLIC_SEARCH_MLT';
export const PUBLIC_SEARCH_MLT_SUCCESS = 'PUBLIC_SEARCH_MLT_SUCCESS';
export const PUBLIC_SEARCH_MLT_ERROR = 'PUBLIC_SEARCH_MLT_ERROR';

export const PUBLIC_SEARCH_MLT_REMOVE = 'PUBLIC_SEARCH_MLT_REMOVE';
export const PUBLIC_SEARCH_MLT_REMOVE_SUCCESS = 'PUBLIC_SEARCH_MLT_REMOVE_SUCCESS';
export const PUBLIC_SEARCH_MLT_REMOVE_ERROR = 'PUBLIC_SEARCH_MLT_REMOVE_ERROR';

export const PUBLIC_LOAD_CONFIG = 'PUBLIC_LOAD_CONFIG';
export const PUBLIC_LOAD_CONFIG_SUCCESS = 'PUBLIC_LOAD_CONFIG_SUCCESS';
export const PUBLIC_LOAD_CONFIG_ERROR = 'PUBLIC_LOAD_CONFIG_ERROR';

export const PUBLIC_SUGGEST_QUERY = 'PUBLIC_SUGGEST_QUERY';
export const PUBLIC_SUGGEST_QUERY_SUCCESS = 'PUBLIC_SUGGEST_QUERY_SUCCESS';
export const PUBLIC_SUGGEST_QUERY_ERROR = 'PUBLIC_SUGGEST_QUERY_ERROR';

export const PUBLIC_SUGGEST_CLEAR = 'PUBLIC_SUGGEST_CLEAR';
export const PUBLIC_SUGGEST_CLEAR_SUCCESS = 'PUBLIC_SUGGEST_CLEAR_SUCCESS';
export const PUBLIC_SUGGEST_CLEAR_ERROR = 'PUBLIC_SUGGEST_CLEAR_ERROR';

export const PUBLIC_LOAD_PROFILE = 'PUBLIC_LOAD_PROFILE';
export const PUBLIC_LOAD_PROFILE_SUCCESS = 'PUBLIC_LOAD_PROFILE_SUCCESS';
export const PUBLIC_LOAD_PROFILE_ERROR = 'PUBLIC_LOAD_PROFILE_ERROR';

export const PUBLIC_UPDATE_TOKEN = 'PUBLIC_UPDATE_TOKEN';
export const PUBLIC_UPDATE_TOKEN_SUCCESS = 'PUBLIC_UPDATE_TOKEN_SUCCESS';
export const PUBLIC_UPDATE_TOKEN_ERROR = 'PUBLIC_UPDATE_TOKEN_ERROR';
